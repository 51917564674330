import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StartView from '../views/StartGameView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/start',
    name: 'start',
    component: StartView
  },
  {
    path: '/bord',
    name: 'bord',
    component: () => import('../views/BordView.vue')
  },
  // Lijnen
  {
    path: '/spel3',
    name: 'Lijnen',
    component: () => import('../views/LijnenView.vue')
  },
  // Memory
  {
    path: '/spel2',
    name: 'Memory',
    component: () => import('../views/MemoryView.vue')
  },
  // TwintigVragen
  {
    path: '/spel1',
    name: 'TwintigVragen',
    component: () => import('../views/TwintigVragenView.vue')
  },
  // Hint1
  {
    path: '/hint1',
    name: 'Hint1',
    component: () => import('../views/Hint1View.vue')
  },
  // Hint2
  {
    path: '/hint2',
    name: 'Hint2',
    component: () => import('../views/Hint2View.vue')
  },
  // Hint3
  {
    path: '/hint3',
    name: 'Hint3',
    component: () => import('../views/Hint3View.vue')
  },
  // Hint4
  {
    path: '/hint4',
    name: 'Hint4',
    component: () => import('../views/Hint4View.vue')
  },
  // Hint5
  {
    path: '/hint5',
    name: 'Hint5',
    component: () => import('../views/Hint5View.vue')
  },
  // Finish
  {
    path: '/finish',
    name: 'Finish',
    component: () => import('../views/FinishView.vue')
  },
  // Dobbel
  {
    path: '/dobbel',
    name: 'Dobbel',
    component: () => import('../views/DobbelView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
