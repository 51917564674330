<template>
  <div id="app">
    <!-- Full size svg background: /assets/background.svg -->
    <object
      type="image/svg+xml"
      :data="backgroundSvg"
      id="svgobject"
      alt="background"
      class="background"
    />

    <!-- Image of /assets/dineke.png as pawn on the game board, standing on tile 1 -->
    <img src="@/assets/dineke.png" class="pawn" />
    <router-view @addTiles="addTiles" />
  </div>
</template>

<script>
const backgroundSvg = require("@/assets/background.svg");

export default {
  name: "App",
  data() {
    return {
      backgroundSvg: backgroundSvg,
      currentTile: 1,
    };
  },
  sockets: {
    success: function () {
      this.$buefy.toast.open({
        message: "Verbonden met de gameserver!",
        type: "is-success",
        queue: false,
      });
    },
  },
  mounted() {
    document.getElementById("svgobject").addEventListener("load", function () {
      const svg = document.getElementById("svgobject");
      const svgDoc = svg.contentDocument;
      const svgRoot = svgDoc.documentElement;

      const tile = svgRoot.getElementById("tile_1");
      const tile_center = tile.getBoundingClientRect();

      const pawn = document.getElementsByClassName("pawn")[0];

      pawn.style.left = tile_center.left + "px";
      pawn.style.top = tile_center.top - 120 + "px";
    });
  },
  methods: {
    hint() {
      if (localStorage.getItem("hint") == undefined) {
        localStorage.setItem("hint", 1);
      }

      if (localStorage.getItem("hint") != "klaar") {
        this.$router.push("/hint" + localStorage.getItem("hint"));
      } else {
        this.$router.push("/dobbel");
      }
    },
    spel() {
      if (localStorage.getItem("spel") == undefined) {
        localStorage.setItem("spel", 1);
      }

      if (localStorage.getItem("spel") != "klaar") {
        this.$router.push("/spel" + localStorage.getItem("spel"));
      } else {
        this.$router.push("/dobbel");
      }
    },
    async addTiles(n) {
      if(n == 0){
        n = 1
      }
    
      if (this.currentTile + n > 25) {
        n = 25 - this.currentTile;
      }
      await this.moveToTile(this.currentTile + n);

      switch (this.currentTile) {
        case 2:
          this.hint();
          break;
        case 3:
          this.spel();
          break;
        case 4:
          await this.teleportToTile(1);
          this.$router.push("/dobbel");
          break;
        case 5:
          this.spel();
          break;
        case 6:
          await this.teleportToTile(10);
          this.$router.push("/dobbel");
          break;
        case 7:
          this.hint();
          break;
        case 8:
          await this.teleportToTile(4);
          this.$router.push("/dobbel");
          break;
        case 9:
          this.hint();
          break;
        case 10:
          await this.teleportToTile(17);
          this.$router.push("/dobbel");
          break;
        case 11:
          this.hint();
          break;
        case 12:
          this.hint();
          break;
        case 13:
          this.hint();
          break;
        case 14:
          this.spel();
          break;
        case 15:
          this.spel();
          break;
        case 16:
          this.spel();
          break;
        case 17:
          await this.teleportToTile(21);
          this.$router.push("/dobbel");
          break;
        case 18:
          this.hint();
          break;
        case 19:
          await this.teleportToTile(14);
          this.$router.push("/dobbel");
          break;
        case 20:
          this.hint();
          break;
        case 21:
          await this.teleportToTile(25);
          this.$router.push("/dobbel");
          break;
        case 22:
          this.spel();
          break;
        case 23:
          await this.teleportToTile(19);
          this.$router.push("/dobbel");
          break;
        case 24:
          await this.teleportToTile(19);
          this.$router.push("/dobbel");
          break;
        case 25:
          this.$router.push("/finish");
          break;
      }
    },
    async moveToTile(newTile) {
      const svg = document.getElementById("svgobject");
      const svgDoc = svg.contentDocument;
      const svgRoot = svgDoc.documentElement;

      const pawn = document.getElementsByClassName("pawn")[0];

      for (let i = this.currentTile; i <= newTile; i++) {
        const tile = svgRoot.getElementById("tile_" + i);
        const tile_center = tile.getBoundingClientRect();

        if (i == this.currentTile + 1) {
          pawn.style.transition = "all 1s ease-in";
        } else if (i == newTile) {
          pawn.style.transition = "all 1s ease-out";
        } else {
          pawn.style.transition = "all .5s linear";
        }

        pawn.style.left = tile_center.left + "px";
        pawn.style.top = tile_center.top - 120 + "px";

        if (i == this.currentTile + 1) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else if (i == newTile) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }

      this.currentTile = newTile;
    },
    async teleportToTile(newTile) {
      const svg = document.getElementById("svgobject");
      const svgDoc = svg.contentDocument;
      const svgRoot = svgDoc.documentElement;

      const tile = svgRoot.getElementById("tile_" + newTile);
      const tile_center = tile.getBoundingClientRect();

      const pawn = document.getElementsByClassName("pawn")[0];

      pawn.style.transition = "all 1s ease-in-out";

      pawn.style.left = tile_center.left + "px";
      pawn.style.top = tile_center.top - 120 + "px";

      this.currentTile = newTile;
    },
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

::-webkit-scrollbar {
  display: none;
}

/* #pawn should be positioned on the game board, standing on tile 1, elements can overlap */
.pawn {
  position: absolute;
  width: 300px;
  height: 300px;
  z-index: 1;
}
</style>
