<template>
  <div class="body">
    <div id="blurred" class="body card">
      <div class="card-content">
        <div class="content">
          <p>
            <span class="type" :style="'--n:5000'">{{ text }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      text: `Beste Dineke,
      
      Sinterklaas had voor jou een mooi pakket aan cadeautjes,
      maar hij maakte een vergissing met de depotjes.

      Jouw stapel pakjes zijn nu ergens anders in belandt,
      het is nu jouw taak om uit te zoeken waar ze zijn gestrand.

      Je bent niet alleen: er zijn ook externe factoren,
      die jouw zoektocht kunnen verbeteren of verstoren.

      Afijn, er is nog een lange reis te gaan,
      en als je trek krijgt, neem gewoon eens lekker een banaan!

      Dank je wel,
      Sinterklaas en de Pieten zien je snel
      `,
    };
  },
  sockets: {
    continue: function () {
      document.getElementById("blurred").classList.add("unblur");
      setTimeout(() => {
        this.$router.push("/dobbel");
      }, 1000);
    },
  },
};
</script>

<style scoped>
/* Unblur animation #unblur */
@keyframes unblur {
  from {
    backdrop-filter: blur(240px);
    opacity: 100%;
  }
  to {
    backdrop-filter: blur(0px);
    opacity: 0%;
  }
}

/* Unblur class #unblur */
.unblur {
  animation: unblur 1s forwards;
}

/* Full size centered #body */
.body {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

/* Frosted glass background #card */
.card {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(240px);
}

/* Card content big, centered */
.card-content {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 37%;
  width: 100vw;
  transform: translate(-25%, -25%);
  text-align: left;
}

/* Paragraph text with typewriter effect */
p {
  font-family: "Courier New", Courier, monospace;
  font-size: 4rem;
  white-space: pre-line !important;
  margin: 0 auto;
  letter-spacing: 0.15em;
}

.type {
  color: #0000;
  background: linear-gradient(-90deg, #000 5px, #0000 0) 10px 0,
    linear-gradient(#000 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: b 1s infinite steps(1),
    t calc(var(--n) * 0.03s) steps(var(--n)) forwards;
}

@keyframes t {
  from {
    background-size: 0 200%;
  }
}
@keyframes b {
  50% {
    background-position: 0 -100%, 0 0;
  }
}
</style>
